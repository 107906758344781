<template>
 <div class="dashboard">
   DASHBOARD
 </div>
</template>

<script>
export default {
  name: "DashboardView"
}
</script>

<style scoped>

</style>