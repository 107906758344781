import Vue from "vue";
import {
    AUTH_REQUEST,
    AUTH_ERROR,
    AUTH_SUCCESS,
    AUTH_LOGOUT
} from "../actions/auth";
import {USER_REQUEST} from "../actions/user";

const state = {
    token: localStorage.getItem("access_token") || '',
    refreshToken: localStorage.getItem("refresh_token") || '',
    status: '',
    authError: '',
    authErrorCode: null,
    hasLoadedOnce: false,
    errorMessages: {
        401: 'Введенный пароль не подходит, либо учетная запись с таким email не существует.',
        423: 'Нужно подтвердить email. На ваш адрес отправлено письмо.',
    },
};

const getters = {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status,
    authError: state => state.authError,
    authErrorCode: state => state.authErrorCode,
};

const actions = {
    [AUTH_REQUEST]: ({commit, dispatch}, user) => {
        return new Promise((resolve, reject) => {
            commit(AUTH_REQUEST);
            Vue.axios({url: "/jwt/create", data: user, method: "POST"})
                .then(resp => {
                    localStorage.setItem("access_token", resp.data.access)
                    localStorage.setItem("refresh_token", resp.data.refresh)
                    Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${resp.data.access}`
                    commit(AUTH_SUCCESS, {accessToken: resp.data.access, refreshToken: resp.data.refresh})
                    dispatch(USER_REQUEST);
                    resolve(resp.data);
                })
                .catch((error) => {
                    commit(AUTH_ERROR, error);
                    localStorage.removeItem("access_token")
                    reject(error)
                });
        });
    },
    [AUTH_LOGOUT]: ({commit}) => {
        return new Promise(resolve => {
            commit(AUTH_LOGOUT);
            localStorage.removeItem("access_token")
            resolve()
        });
    }
};

const mutations = {
    [AUTH_REQUEST]: state => {
        state.status = "loading"
    },
    [AUTH_SUCCESS]: (state, {accessToken, refreshToken}) => {
        state.status = "success"
        state.token = accessToken
        state.refreshToken = refreshToken
        state.hasLoadedOnce = true
    },
    [AUTH_ERROR]: (state, error) => {
        state.status = "error"
        state.hasLoadedOnce = true
        if (!error.response) {
            return;
        }
        if (state.errorMessages[error.response.status] !== undefined) {
            state.authError = state.errorMessages[error.response.status]
        }
        state.authErrorCode = error.response.status
    },
    [AUTH_LOGOUT]: state => {
        state.token = ""
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
