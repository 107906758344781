import {CONTRACTOR_REQUEST, CONTRACTOR_ERROR, CONTRACTOR_SUCCESS} from "../actions/contractor"
import Vue from "vue"

const state = {status: "", contractors: []}

const getters = {
    contractors: state => state.contractors,
    contractorStatus: state => state.status,
    getContractor: (state) => (id) => {
        return state.contractors.find(contractor => contractor.id === id)
    }
};

const actions = {
    async [CONTRACTOR_REQUEST]({commit}) {
        commit(CONTRACTOR_REQUEST)
        try {
            let response = await Vue.axios.get("/contractors/my")
            commit(CONTRACTOR_SUCCESS, response.data)
        } catch (e) {
            commit(CONTRACTOR_ERROR)
        }
    },
};

const mutations = {
    [CONTRACTOR_REQUEST]: state => {
        state.status = "loading"
    },
    [CONTRACTOR_SUCCESS]: (state, resp) => {
        state.status = "success"
        Vue.set(state, "contractors", resp)
    },
    [CONTRACTOR_ERROR]: state => {
        state.status = "error"
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
