import {CONNECTION_TYPE_REQUEST, CONNECTION_TYPE_ERROR, CONNECTION_TYPE_SUCCESS} from "../actions/connectionType"
import Vue from "vue"

const state = {status: "", connectors: []}

const FOR_CIRCLE_ONLY = 1
const FOR_STRAIGHT_ONLY = 2
const FOR_BOTH = 3

const getters = {
    connectors: state => state.connectors,
    circleConnectors: state => {
        return state.connectors.filter(connector => connector.type === FOR_CIRCLE_ONLY || connector.type === FOR_BOTH)
    },
    straightConnectors: state => {
        return state.connectors.filter(connector => connector.type === FOR_STRAIGHT_ONLY || connector.type === FOR_BOTH)
    },
    connectorsStatus: state => state.status

};

const actions = {
    async [CONNECTION_TYPE_REQUEST]({commit}) {
        commit(CONNECTION_TYPE_REQUEST)
        try {
            let response = await Vue.axios.get("/product/connection/types")
            commit(CONNECTION_TYPE_SUCCESS, response.data)
        } catch (e) {
            commit(CONNECTION_TYPE_ERROR)
        }
    }
};

const mutations = {
    [CONNECTION_TYPE_REQUEST]: state => {
        state.status = "loading"
    },
    [CONNECTION_TYPE_SUCCESS]: (state, resp) => {
        state.status = "success"
        Vue.set(state, "connectors", resp)
    },
    [CONNECTION_TYPE_ERROR]: state => {
        state.status = "error"
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
