import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DashboardView from "@/views/DashboardView";
import LoginView from "@/views/LoginView";
import {USER_REQUEST} from "@/store/actions/user";
import store from "@/store";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardView
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        meta: {
            layout: 'login-layout',
            requiresAuth: false
        }
    },
    {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
    {
        path: '/cart',
        name: 'cart',
        component: () => import(/* webpackChunkName: "cart" */ '../views/CartView.vue')
    },
    {
        path: '/orders',
        name: 'orderList',
        component: () => import(/* webpackChunkName: "orderList" */ '../views/OrderListView.vue')
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/ProfileView.vue')
    },
    {
        path: '/product/catalog',
        name: 'productCatalog',
        component: () => import(/* webpackChunkName: "productCatalog" */ '../views/ProductCatalogView.vue')
    },
    {
        path: '/product/catalog/duct',
        name: 'ductProduct',
        component: () => import(/* webpackChunkName: "ductProduct" */ '../views/product/DuctProductView')
    },
    {
        path: '/product/catalog/duct/circle',
        name: 'circleDuctProduct',
        component: () => import(/* webpackChunkName: "circleDuctProduct" */ '../views/product/CircleDuctProductView')
    },
    {
        path: '/product/catalog/elbow',
        name: 'elbowProduct',
        component: () => import(/* webpackChunkName: "elbowProduct" */ '../views/product/ElbowProductView')
    },
    {
        path: '/product/catalog/elbow/circle',
        name: 'circleElbowProduct',
        component: () => import(/* webpackChunkName: "circleElbowProduct" */ '../views/product/CircleElbowProductView')
    },
    {
        path: '/product/catalog/duck',
        name: 'duckProduct',
        component: () => import(/* webpackChunkName: "duckProduct" */ '../views/product/DuckProductView')
    },
    {
        path: '/product/catalog/adapter',
        name: 'adapterProduct',
        component: () => import(/* webpackChunkName: "adapterProduct" */ '../views/product/AdapterProductView')
    },
    {
        path: '/product/catalog/adapter/circle',
        name: 'circleAdapterProduct',
        component: () => import(/* webpackChunkName: "circleAdapterProduct" */ '../views/product/CircleAdapterProductView')
    },
    {
        path: '/product/catalog/tee',
        name: 'teeProduct',
        component: () => import(/* webpackChunkName: "teeProduct" */ '../views/product/TeeProductView')
    },
    {
        path: '/product/catalog/tee/circle',
        name: 'circleTeeProduct',
        component: () => import(/* webpackChunkName: "circleTeeProduct" */ '../views/product/CircleTeeProductView')
    },
    {
        path: '/product/catalog/hood/circle',
        name: 'hoodProduct',
        component: () => import(/* webpackChunkName: "hoodProduct" */ '../views/product/HoodProductView')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(record => {
        if (record.meta.requiresAuth === undefined) {
            return true
        }
        return  record.meta.requiresAuth
    })
    if(requiresAuth) {
        try {
            await store.dispatch(USER_REQUEST);
            next();
        } catch (err) {
            console.error(err);
            next('/login');
        }
    } else {
        next()
    }
})


export default router
