<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>Войдите</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                      v-model="username"
                      prepend-icon="mdi-account"
                      name="login"
                      label="Логин"
                      type="text"
                  ></v-text-field>
                  <v-text-field
                      v-model="password"
                      prepend-icon="mdi-lock"
                      name="password"
                      label="Пароль"
                      type="password"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="login">Войти</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {AUTH_REQUEST} from "@/store/actions/auth";

export default {
  name: 'LoginView',
  props: {
    source: String,
  },
  data: function () {
    return {
      username:'',
      password:''
    }
  },
  methods: {
    login: async function () {
      const {username, password} = this;
      try {
        await this.$store.dispatch(AUTH_REQUEST, {username, password})
        this.error = false;
        // await this.$store.dispatch(AUTH_REQUEST, {username, password})
        await this.$router.push('/dashboard')
      } catch (err) {
        this.error = true;
      }
    }
  },
};
</script>

<style scoped>

</style>
