import {SNACKBAR, SNACKBAR_TEXT, SNACKBAR_COLOR, SNACKBAR_TIMEOUT} from '../actions/snackbar';

const state = {
  snackbar: false,
  text: '',
  color: 'green darken-2',
  timeout: 4000,
};

const getters = {
  snackbar: state => state.snackbar,
  text: state => state.text,
  color: state => state.color,
  timeout: state => state.timeout,
};

const actions = {
};

const mutations = {
  [SNACKBAR]: (state, value) => {
    state.snackbar = value;
  },
  [SNACKBAR_TEXT]: (state, value) => {
    state.text = value;
  },
  [SNACKBAR_COLOR]: (state, value) => {
    state.color = value;
  },
  [SNACKBAR_TIMEOUT]: (state, value) => {
    state.timeout = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
