<template>
  <div>
    <v-navigation-drawer app class="nav" permanent>
      <img class="nav-logo" alt="ke-logo" src="@/assets/ke-logo.jpg">
      <v-list>
        <v-list-item
            link v-for="item in navLinks"
            :key="item.title"
            :to="{name: item.routeName}"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app>
      Приложение| Контрагент: <span v-if="getContractor(cartContractorId)">{{getContractor(cartContractorId).short_name}}</span>
      <v-spacer/>
      <v-menu
          left
          bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-chip
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-account</v-icon>
            {{ getProfile.username }}
          </v-chip>
        </template>

<!--        <v-list>-->
<!--          <v-list-item-->
<!--              link-->
<!--              :to="{name:'profile'}"-->
<!--          >-->
<!--            <v-list-item-title>Настройки</v-list-item-title>-->
<!--          </v-list-item>-->
<!--        </v-list>-->
        <v-list>
          <v-list-item @click="logout()">
            <v-list-item-title>Выйти</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <router-view/>
      </v-container>
    </v-main>

    <v-footer app>
      <v-spacer/>
      кузнецк инжиниринг 2022
    </v-footer>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {CONTRACTOR_REQUEST} from "@/store/actions/contractor";
import {USER_LOGOUT} from "@/store/actions/user";

export default {
  name: "UserLayout",
  data: function () {
    return {
      navLinks: [
        {
          routeName: 'dashboard',
          title: 'Главная',
          icon: 'mdi-view-dashboard'
        },
        {
          routeName: 'productCatalog',
          title: 'Каталог',
          icon: 'mdi-format-list-bulleted-type'
        },
        {
          routeName: 'orderList',
          title: 'Заявки',
          icon: 'mdi-format-list-bulleted-type'
        },
        {
          routeName: 'cart',
          title: 'Корзина',
          icon: 'mdi-cart'
        },
        {
          routeName: 'about',
          title: 'О проекте',
          icon: 'mdi-view-dashboard'
        }
      ]
    }
  },
  async mounted() {
    await this.$store.dispatch(CONTRACTOR_REQUEST)
  },
  methods: {
    ...mapActions([CONTRACTOR_REQUEST]),
    logout() {
      this.$store.dispatch(USER_LOGOUT);
      this.$router.push({name: 'login'})
    }
  },
  computed: {
    ...mapGetters(['getProfile', "getContractor","cartContractorId"]),
  },

}
</script>

<style scoped>

</style>