import {USER_REQUEST, USER_ERROR, USER_SUCCESS, USER_LOGOUT} from "../actions/user";
import Vue from "vue";
import { AUTH_LOGOUT } from "../actions/auth";

const state = { status: "", profile: {} };

const getters = {
  getProfile: state => state.profile,
  isProfileLoaded: state => !!state.profile.username
};

const actions = {
  [USER_REQUEST]: ({ commit, dispatch}) => {
    commit(USER_REQUEST);
    Vue.axios({ url: "/users/me/" })
      .then(resp => {
        // console.log(resp)
        commit(USER_SUCCESS, resp.data);
      })
      .catch(() => {
        commit(USER_ERROR);
        commit(USER_LOGOUT);
        dispatch(AUTH_LOGOUT);
      });
  },
  [USER_LOGOUT]: ({ commit, dispatch}) => {
    commit(USER_LOGOUT);
    dispatch(AUTH_LOGOUT);
  }
};

const mutations = {
  [USER_REQUEST]: state => {
    state.status = "loading";
  },
  [USER_SUCCESS]: (state, resp) => {
    state.status = "success";
    Vue.set(state, "profile", resp);
  },
  [USER_ERROR]: state => {
    state.status = "error";
  },
  [USER_LOGOUT]: state => {
    state.profile = {};
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
