import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './services/api'
import VueAxios from 'vue-axios'
import vuetify from "@/plugins/vuetify"
import {AUTH_LOGOUT} from "@/store/actions/auth";
import LoginLayout from "@/layouts/LoginLayout";
import UserLayout from "@/layouts/UserLayout";

Vue.config.productionTip = false

Vue.use(VueAxios, api)

const token = localStorage.getItem('access_token')
if (token) {
  Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
} else {
  router.push('/login')
}
Vue.axios.interceptors.response.use(undefined, async (err) => {
  // console.log(err.config)
  // console.log(err.response.status)
  if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
    await store.dispatch(AUTH_LOGOUT)
    // store.dispatch(AUTH_ERROR, err)
    await router.push('/login')
  }
  // if (err.response.status === 403) {
  //     await router.push('/403')
  // }
  throw err;
});

Vue.component('login-layout', LoginLayout)
Vue.component('user-layout', UserLayout)



console.log(process.env.VUE_APP_API_BASE_URL)
new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
