import {COATING_REQUEST, COATING_ERROR, COATING_SUCCESS} from "../actions/coating"
import Vue from "vue"

const state = {status: "", coating: []}

const getters = {
    coating: state => state.coating,
    coatingStatus: state => state.status
};

const actions = {
    async [COATING_REQUEST]({commit}) {
        commit(COATING_REQUEST)
        try {
            let response = await Vue.axios.get("/base-material/coating")
            commit(COATING_SUCCESS, response.data)
        } catch (e) {
            commit(COATING_ERROR)
        }
    }
};

const mutations = {
    [COATING_REQUEST]: state => {
        state.status = "loading"
    },
    [COATING_SUCCESS]: (state, resp) => {
        state.status = "success"
        Vue.set(state, "coating", resp)
    },
    [COATING_ERROR]: state => {
        state.status = "error"
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
