import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import auth from "./modules/auth";
// import cities from "./modules/cities";
import snackbar from "./modules/snackbar";
import cart from "./modules/cart";
import sheetMetal from "./modules/sheetMetal";
import coating from "@/store/modules/coating";
import contractors from "@/store/modules/contractor";
import connections from "@/store/modules/connectionType";

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
    modules: {
        user,
        auth,
        // cities,
        snackbar,
        cart,
        sheetMetal,
        coating,
        connections,
        contractors,
    },
    strict: debug
});
