import {CART_REQUEST, CART_ERROR, CART_SUCCESS, CART_SET_CONTRACTOR} from "../actions/cart"
import Vue from "vue"

const state = {status: "", cartItems: [], cartContractorId:null}

const getters = {
    cartItems: state => state.cartItems,
    cartStatus: state => state.status,
    cartContractorId: state =>state.cartContractorId,
};

const actions = {
    async [CART_REQUEST]({commit}) {
        commit(CART_REQUEST)
        try {
            let cartItemResponse = await Vue.axios.get("/cart/items")
            let cartContractorResponse = await Vue.axios.get("/cart/contractor")

            commit(CART_SET_CONTRACTOR, cartContractorResponse.data.cartContractorId)
            commit(CART_SUCCESS, cartItemResponse.data)
        } catch (e) {
            commit(CART_ERROR)
        }
    },
    async [CART_SET_CONTRACTOR]({commit, contractorId}) {
        try {
            await Vue.axios.post("/cart/contractor",{contractorId:contractorId})
            commit(CART_SET_CONTRACTOR, contractorId)
        } catch (e) {
            commit(CART_ERROR)
        }
    },
};

const mutations = {
    [CART_REQUEST]: state => {
        state.status = "loading"
    },
    [CART_SUCCESS]: (state, resp) => {
        state.status = "success"
        Vue.set(state, "cartItems", resp)
    },
    [CART_SET_CONTRACTOR]: (state, contractorId) => {
        Vue.set(state, "cartContractorId", contractorId)
    },
    [CART_ERROR]: state => {
        state.status = "error"
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
