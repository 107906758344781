<template>
  <v-app>
    <component :is="layout">
      <router-view :key="$route.fullPath"/>
    </component>
  </v-app>
</template>

<script>

export default {

  computed: {
    layout() {
      return this.$route.meta.layout || 'user-layout'
    },
  },

}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.nav {
  &-logo {
    width: 100%;
    height: auto;
  }
}
</style>